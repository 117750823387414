@tailwind base;
@tailwind components;
@tailwind utilities;
/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

$primaryColor: #166079;

input[type='file'] {
  opacity: 0; /* make transparent */
}

.ms-Pagination-container > div {
  display: flex;
}

.hideMenu {
  @media (max-width: 1023px) {
    display: none !important;
  }
}

.selected,
.weeklyCell:hover {
  background-color: $primaryColor;
}

.multiSelectContainer .selected {
  background-color: white !important;
  color: black;
}

.multiSelectContainer .selected:hover {
  background-color: #0096fb !important;
  color: white !important;
}

.multiSelectContainer .highlightOption {
  background: white !important;
  color: black !important;
}

.multiSelectContainer .highlightOption:hover {
  background-color: #0096fb !important;
  color: white !important;
}

::-webkit-scrollbar {
  width: 20px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
}
::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  -webkit-background-clip: content-box;
  background-clip: content-box;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}
.ms-Button--primary {
  // This line is required to prevent tailwind from overriding the fluent ui button
  background-color: $primaryColor !important;
}

@media (min-width: 1440px) {
  .container {
    max-width: 1440px !important;
  }
}
@media (min-width: 1920px) {
  .container {
    max-width: 1920px !important;
  }
}
